<template>
  <div>
    <div class="myHeader">
      <input v-model="myIpt" type="text" placeholder="搜索礼品名称" @keyup.enter="getMsg()">
      <p @click="cancel()">取消</p>
      <img class="searchs" src="../../static/giftIndex/search.png" alt="" @click="getMsg()">
      <img class="clears" src="../../static/giftIndex/xxx.png" alt="" @click="clearss()">
    </div>
    <div class="myTitles">
      <div v-if="myIndex === 1" class="myTitlesMain">
        <p class="zlBig">筑龙原创</p>
        <p class="zlSmall" @click="setIndex(2)">京东优选</p>
      </div>
      <div v-else class="myTitlesMain">
        <p class="zlSmall1" @click="setIndex(1)">筑龙原创</p>
        <p class="zlBig1">京东优选</p>
      </div>

    </div>
    <div class="mian">

      <div v-if="myIndex === 1" class="list">
        <div v-for="(item,index) in zlProduct" :key="index" class="list_item" @click="zlDetail(item.id)">
          <div class="item_top">
            <img :src="item.img" alt="">
          </div>
          <div class="item_bottom">
            <div class="title_box">
              <p class="title">{{ item.gift_name }}</p>
            </div>
            <div class="zlBtm">
              <img class="mmon" src="../../static/giftIndex/mmon.png" alt="">
              <p class="myPrice">{{ item.quantity }}</p>
              <p class="stock">库存<span>{{ item.num }}</span></p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="myIndex === 2" class="list">
        <div v-for="(item,index) in jdProduct" :key="index" class="list_item" @click="jdDetail(item.id)">
          <div class="item_top">
            <img :src="item.img" alt="">
          </div>
          <div class="item_bottom">
            <div class="title_box">
              <p class="title">{{ item.gift_name }}</p>
            </div>
            <div class="zlBtm">
              <img class="mmon" src="../../static/giftIndex/mmon.png" alt="">
              <p class="myPrice">{{ item.quantity }}</p>
              <p class="stock">库存<span>{{ item.num }}</span></p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div v-if="myIndex === 1" class="zl_main">
        <div v-for="(item,index) in zlProduct" :key="index" class="zl_mains" @click="zlDetail(item.id)">
          <div class="zl_mainsTop">
            <img class="giftImg" :src="item.img" alt="">
          </div>
          <div class="zl_mainsBottom">
            <p class="commodityTitle">{{ item.gift_name }}</p>
            <div class="zlBtm">
              <img class="mmon" src="../../static/giftIndex/mmon.png" alt="">
              <p class="myPrice">{{ item.quantity }}</p>
              <p class="stock">库存<span>{{ item.num }}</span></p>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div v-if="myIndex === 2" class="zl_main">
        <div v-for="(item,index) in jdProduct" :key="index" class="zl_mains" @click="jdDetail(item.id)">
          <div class="zl_mainsTop">
            <img class="giftImg" :src="item.img" alt="">
          </div>
          <div class="zl_mainsBottom">
            <p class="commodityTitle">{{ item.gift_name }}</p>
            <div class="zlBtm">
              <img class="mmon" src="../../static/giftIndex/mmon.png" alt="">
              <p class="myPrice">{{ item.quantity }}</p>
              <p class="stock">库存<span>{{ item.num }}</span></p>
            </div>
          </div>
        </div>
      </div> -->

    </div>
  </div>
</template>

<script>
import { keyWordSearch } from '@/api/my_user'
import { Toast } from 'vant'
export default {
  data() {
    return {
      myIpt: this.$route.query.msg,
      myIndex: 1,
      zlProduct: [],
      jdProduct: []
    }
  },
  mounted() {
    this.inits()
  },
  methods: {
    clearss() {
      this.myIpt = ''
    },
    inits() {
      // if (this.$route.query.msg) {
      //   this.getMsg()
      // }
      if (this.myIpt) {
        this.getMsg()
      }
    },
    zlDetail(id) {
      /*this.$router.push({
        path: '/giftinfo',
        query: {
          id: id
        }
      })*/
      this.$router.push({
        path: '/gift/giftExchange',
        query: {
          id: id
        }
      })
    },
    jdDetail(id) {
      this.$router.push({
        path: '/gift/giftExchange',
        query: {
          id: id
        }
      })
    },
    getMsg() {
      console.log('走这里了' + this.myIpt)
      if (!this.myIpt) {
        Toast('没有关键词')
      } else {
        var params = {
          keyword: this.myIpt
        }
        keyWordSearch(params).then(res => {
          this.zlProduct = res.result.zlProduct
          this.jdProduct = res.result.jdProduct
          if (this.zlProduct.length <= 0 && this.jdProduct.length > 0) {
            this.myIndex = 2
          }
        })
      }
    },
    cancel() {
      this.$router.go(-1)
    },
    setIndex(index) {
      this.myIndex = index
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang="scss" scoped>
.myHeader{
  width: 100%;
  height: 117px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  position: relative;
  input{
    width: 566px;
    height: 63px;
    background: #f7f7f7;
    border-radius: 40px;
    margin: 28px 0 0 31px;
    padding-left: 55px;
  }
  img{
    width: 24px;
    height: 25px;
  }
  p{
    color: #565759;
    padding: 41px 20px 0 0;
  }

}

.list{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
}
.list_item{
  width: 330px;
  margin: 30px 0 0 30px;
  border-radius: 10px 10px 10px 10px;
  background-color: white;
}
.item_top{
  width: 100%;
  height: 330px;
  img{
    width: 100%;
    height: 330px;
    border-radius: 10px 10px 0 0;
  }
}
.item_bottom{
  padding: 16px 16px 0 16px;
}
.title_box{
  height: 90px;
}
.title{
  color: #333333;
  font-size: 30px;
  // height: 90px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-all;
  -webkit-box-orient: vertical;
}
.zlBtm{
  display: flex;
  align-items: center;
  margin: 18px 0 18px 0;
  height: 30px;
}
.mmon{
  width: 26px;
  height: 30px;
}
.myPrice{
  color: #ee2e2e;
  font-size: 36px;
  padding: 0 0 0 10px;
}
.stock{
  color: #999999;
  font-size: 28px;
  padding: 0 0 0 10px;
}

.zlBig{
  font-size: 36px;
  font-weight: 600;
  // text-shadow: 5px 2px 2px #f28e8e;
}
.zlSmall{
  color: #434343;
  padding: 16px 0 0 20px;
}
.zlBig1{
  font-size: 36px;
  font-weight: 600;
  padding: 0 0 0 16px;
  // text-shadow: 5px 2px 2px #f28e8e;
}
.zlSmall1{
  color: #434343;
  padding: 16px 0 0 0px;
}
.myTitlesMain{
  display: flex;
  margin: 60px 0 0 30px;
}
.clears{
  width: 32px;
  height: 32px;
  position: absolute;
  right: 120px;
  top: 45px;
}
.searchs{
  position: absolute;
  left: 50px;
  top: 45px;
}
.myTitles{
  width: 100%;
  height: 70px;
}
// .mmon{
//   width: 26px;
//   height: 30px;
// }
// .myPrice{
//   color: #FF0000;
//   font-size: 32px;
//   font-weight: 600;
//   padding: 0 0 0 10px;
// }
// .stock{
//   color: #888888;
//   font-size: 26px;
//   padding: 0 0 0 10px;
// }
// .zlBtm{
//   display: flex;
//   align-items: center;
//   margin: 10px 0 0 0 ;
// }
.commodityTitle{
  color: #222222;
  font-size: 30px;
  font-weight: 600;
  line-height: 50px;
}
.giftImg{
  width: 330px;
  height: 330px;
  // border-radius: 10px;
}
.zl_mains{
  // width: 330px;
  width: calc(100% - 60px) / 2;
  margin-left: 20px;
  // margin: 30px 0 0 0;
}
.zl_main{
    width: 98%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // justify-content: space-around;
}
.search{
  width: 100%;
  height: 110px;
  display: flex;
  background: #fff;
}
.searchLeft img{
  width: 38px;
  height: 38px;
}
.searchLeft{
  width: 120px;
  display: flex;
  margin: 11px 0 0 30px;
  p{
    font-size: 30px;
    color: #333333;
    padding: 0 0 0 15px;
  }
}
.searchRight{
  width: 561px;
  height: 70px;
  position: relative;
  margin: 0 0 0 30px;
  img{
    width: 25px;
    height: 25px;
    position: absolute;
    left: 30px;
    top: 21px;
  }
}
.searchRight input{
  width: 480px;
  height: 66px;
  border-radius: 75px;
  padding: 0 0 0 70px;
  color: #777777;
  letter-spacing: 3px;
  font-size: 24px;
  background: #F7F7F7;
}
</style>
